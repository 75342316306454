<template>
  <div class="main-wrapper">
    <div class="fs-container row m-0">
      <div class="fs-inner-container col-lg-6 content">
        <div class="fs-content pt-5 px-5">
          <!-- Search -->
          <section class="search">
            <div class="row">
              <div class="col-md-12">
                <!-- Row With Forms -->
                <div class="row with-forms">
                  <!-- Main Search Input -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">ZipCode/City</label>
                      <v-select
                        v-model="zipChange"
                        :options="zipcodes"
                        placeholder="Search ZipCode/City"
                        class="zipcode"
                        @search="fetchZipCode"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="listings-container">
            <div class="row fs-switcher pt-4">
              <div class="col-md-6">
                <p
                  v-if="total_pages"
                  class="showing-results"
                >
                  {{ total_pages }} Results Found
                </p>
                <p
                  v-else
                  class="showing-results text-danger font-weight-bold"
                >
                  No Record Found
                </p>
              </div>
            </div>
            <!-- Listings -->
            <div class="row fs-listings">
              <!-- Listing Item -->
              <div
                v-for="(list,index) in places"
                :key="index"
                class="col-lg-6 col-md-6 col-sm-6 grid-layout-list mb-4"
              >
                <div class="list-cap">
                  <div class="list-cap-list mb-4">
                    <div
                      class="img-list"
                      style="width: 100%; height: 200px"
                    >
                      <img
                        v-if="list.image"
                        :src="baseUrl + list.image"
                        alt=""
                        class="img-fluid"
                        style="width: 100%; height: 100%; object-fit: cover"
                      >
                      <img
                        v-else
                        src="/static/images/hh_place.jpg"
                        alt=""
                        class="img-fluid"
                        style="width: 100%; height: 100%; object-fit: cover"
                      >
                    </div>
                    <div class="list-cap-content list-cap-content--style" style="height:170px">
                      <!-- <span
                        class="badge badge-pill badge-primary text-uppercase mr-2"
                      >Deals</span> -->
                    
                      <!-- <Rating></Rating> -->
                      <h5  style="color:#5e72e4 !important">
                        50% ff pizza
                      </h5>
                      <h6 class="mt-2">
                        {{ list.title }}
                      </h6>
                      <div
                        class="address-bar"
                        style="color: #525f7f;margin-top:-9px"
                      >
                        <span style="font-size:16px !important;">
                          Business Address Here
                        </span>
                        <br>

                         <span style="font-size:16px !important"> <i
                    class="fa fa-map-marker"
                    aria-hidden="true"
                  /> {{ list.city }}, {{ list.state }} {{ list.zipcode }}
                  
                         <span
                            v-if="$isAuth"
                           @click="showModel(list.id)"
                            style="font-size: 12px;cursor: pointer;border-radius:0% !important;float:right"
                            class="mt-1 pt-1 pb-1 font-weight-bold badge-pill badge-danger text-uppercase mr-2"
                          > 
                             <i
                            class="fa fa-ban"
                            aria-hidden="true"
                          /> Report </span>

                            <span
                                v-else
                                data-toggle="modal"
                                data-target="#login"
                                style="font-size: 13px;cursor: pointer;border-radius:0% !important;float:right"
                                class="pt-1 pb-1 font-weight-bold badge-pill badge-danger text-uppercase mr-2"
                              > 
                                <i
                                  class="fa fa-ban"
                                  aria-hidden="true"
                                /> Report
                             </span>
                         
                        </span>


                        
                      </div>
                       
                    </div>
                    <span
                      v-if="$isAuth"
                      :id="list.id"
                      style="cursor: pointer"
                      title="Like"
                      class="round-pill like-banner d-block bg-primary"
                      @click="like(list.id)"
                    ><i class="fa fa-thumbs-up" />
                      {{ list.total_likes }}</span>
                    <span
                      v-else
                      :id="list.id"
                      data-toggle="modal"
                      data-target="#login"
                      style="cursor: pointer"
                      title="Like"
                      class="round-pill like-banner d-block bg-primary"
                    ><i class="fa fa-thumbs-up" />
                      {{ list.total_likes }}</span>
                  </div>
                </div>
              </div>
              <!-- Listing Item / End -->
            </div>
            <!-- Listings Container / End -->
            <!-- Pagination Container -->
            <div class="row fs-listings">
              <div class="col-md-12">
                <!-- Pagination -->
                <div class="clearfix" />
                <pagination
                  v-if="(Object.keys(places).length > 9)"
                  v-model="page"
                  :records="parseFloat(total_pages-1)"
                  :per-page="10"
                  @paginate="callback"
                />
                <div class="clearfix" />
                <!-- Pagination / End -->
              </div>
            </div>
            <!-- Pagination Container / End -->
          </section>
        </div>
      </div>
      <div class="fs-inner-container col-lg-6 map-fixed" style="height:75vh;">
        <map-banner
          v-if="coordinates"
          :key="coordinates.lat"
          layout="sidebar"
          :coordinates="coordinates"
          :events="places"
          :redoSearch="redoSearch"
            :locateMe="locateMe"
        />
      </div>
    </div>
    <!-- //login modal -->
    <div
      id="login"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      aria-labelledby="HHModalLabel"
      style="margin-top: 90px"
    >
      <div
        class="modal-dialog"
        role="document"
      >
        <div class="modal-content">
          <div
            class="modal-header"
            style="
              background-color: currentColor;
              padding: 6px 6px 6px 18px !important;
            "
          >
            <h5 style="color: white">
              Login
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                style="color: white"
                aria-hidden="true"
              >&times;</span>
            </button>
          </div>
          <loginModal />
        </div>
      </div>
    </div>

    <!-- report modal  -->
     <div
      id="report"
      data-toggle="modal"
      ref="report_modal"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      aria-labelledby="HHModalLabel"
      style="margin-top:100px;"
    >
      <div
        class="modal-dialog "
        role="document"
      >
        <div class="modal-content">
          <div
            class="modal-header"
            style="background-color:currentColor;"
          >     
            <h5 style="color:white;">
              Report
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                style="color:white;"
                aria-hidden="true"
              >&times;</span>
            </button>
          </div> 
          <div class="modal-body text-center">
            <div>
              <h5
                class="f-700 text-uppercase mb-0 mt-2"
                style="padding-bottom: 5% !important;"
              >
                THANK YOU FOR HELPING US
             
                <span>Maintain our LIstings</span>
              </h5>
              <form class="text-left">
                <label class="container">
                  <input
                    ref="is_closed"
                    v-model="obj.is_closed"
                    type="checkbox"
                  >
                  <span class="checkmark" />
                  Business closed
                </label>
                <label class="container">
                  <input
                    ref="is_hateful"
                    v-model="obj.is_hateful"
                    type="checkbox"
                  >
                  <span class="checkmark" />
                  Hateful, sexual, fake inappropriate
                </label>
                <button
                  type="button"
                  class="btn btn-primary btn-padded w-100 f-700 text-uppercase"
                  @click="handleSubmit(obj)"
                >
                  SUbmit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MapBanner from 'Components/globalFrontendComponents/MapBanner';
// import Pagination from 'Components/globalFrontendComponents/Pagination';
import Pagination from 'vue-pagination-2';

import vSelect from 'vue-select';
// import axios from 'axios';
import {axios} from '../services/http-common';
import 'vue-select/dist/vue-select.css';
import MyList from 'Data/listing.json';
import loginModal from '../components/LoginModal.vue';
// import axios from 'axios';
export default {
	components: {
		MapBanner: MapBanner,
      vSelect:vSelect,
      Pagination:Pagination,
      loginModal
	},
	data(){
		return{
      obj:{
				is_closed:'',
				is_hateful:''
			},
         baseUrl:process.env.VUE_APP_STORAGE_URL,
         data: MyList.data,
         places: '',
         places_list:[],
         coordinates: '',
         zipcodes:[{label: "Houston TX 77007"}],
         spinner:true,
         zipChange: 'Houston TX 77007',
         total_pages: '',
         page: 1,
         area:'',
         reportFor: 'ads',
		}
	},

   watch: {
      zipChange: function(val) {
         if (val) {
            // let area = val.label.replace(/\s/g, '-');
            let area = val.label;
            if(area){
              this.area = area;
              axios
              .get(`${process.env.VUE_APP_API_URL}deals-events?orderBy=DESC&sortBy=total_likes&filter=s&isWeb=1&area=`+area)
              .then(response => {
                if (!response.error) {
                    this.places = response.data.data;
                    this.total_pages = response.data.total_pages;
                    this.coordinates = response.data.coordinates;
                }
              });
            }
            else
            localStorage.setItem('hh_zip',val);
         }
      },
  },
   async mounted() {
    var check = $('#navbar_global').hasClass('show');
    if(check)
      $('#navbar_global').removeClass('show');
  
      let res = await this.getPlaces();
      this.places = res.data;
      this.total_pages = res.total_pages;
      this.coordinates = res.coordinates;
      
   },
   methods:{
    
      redoSearch(){
       let lat = localStorage.getItem('lat');
       let lng = localStorage.getItem('lng');

        let page = parseFloat(this.page);
         if(this.page > 1)
            page = ( (parseFloat(this.page) -1) * 10);
         else{
            page = 0;
            }
         return axios
            .get( `${process.env.VUE_APP_API_URL}deals-events?orderBy=DESC&sortBy=total_likes&lat=${lat}&lng=${lng}&isWeb=1&offset=${page}&type=redo_search`)
            .then(res => {
                this.places = res.data.data;
                this.total_pages = res.data.total_pages;
                this.coordinates = res.data.coordinates;
                 this.zipChange =  res.data.area.city +','+ res.data.area.state;
         });
    },
      locateMe(){
       return  axios
            .get('https://api.ipgeolocation.io/ipgeo?apiKey=fab1a7a1c31a41ee87451a0f63201f73')
            .then((response)  => {
              localStorage.setItem('lat',response.data.latitude);
              localStorage.setItem('lng', response.data.longitude);
              this.redoSearch();
            })
            .catch(function() {});
     },
     showModel(id){
      this.id = id;
      $('#report').modal('toggle');
      
     },
     	handleSubmit(obj) {
		if(this.reportFor == 'ads'){
			this.report('/report-ad/');
		}
	},
  	report(type){
		return axios
      .put(type + this.id, this.obj)
      .then(response => {
         $("#report").modal('hide');
          this.$toast.success('Thank You For Your Feedback', {
                    position: "bottom-right",
                    timeout: 2508,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                    top:'300px',
                    });
      })
      .catch(error => {
        console.log('Errro !');
      });
	},
      like(id){
         let data = {};
         data.likeable_type = 'ads';
         data.likeable_id = id;

         return axios
            .post( `${process.env.VUE_APP_API_URL}likes`,data)
            .then(response => {
            if(!response.error) {
               $('#'+id).html('<i class="fa fa-thumbs-up"></i> ' + response.data.data.total_likes);
            }
         });
      },
      placeListChange(){
         return axios
            .get( `${process.env.VUE_APP_API_URL}deals-events?orderBy=DESC&sortBy=total_likes&filter=s&isWeb=1&area=Houston-TX`)
            .then(response => {
            if (!response.error) {
               return response.data;
            }
         });
      },
      getPlaces(){
         return axios
            .get( `${process.env.VUE_APP_API_URL}deals-events?orderBy=DESC&sortBy=total_likes&filter=s&isWeb=1&area=Houston-TX`)
            .then(response => {
            if (!response.error) {
               return response.data;
            }
         });
      },
      fetchZipCode (search, loading) {
         if(search){
            return axios
            .get(`${process.env.VUE_APP_API_URL}filter-location/`+search+'/hh')
            .then(response => {
               if (!response.error) {
                  this.zipcodes = response.data.data;
               }
            });
         }
      },
       fetchPlace(search, loading) {
         if(search){
            return axios
            .get(`${process.env.VUE_APP_API_URL}filter-place/`+search)
            .then(response => {
               if (!response.error) {
                  this.places_list = response.data.data;
               }
            });
         }
      },
      callback: function(data) {
         let page =( parseFloat(data) * 10);
         axios.get(`${process.env.VUE_APP_API_URL}deals-events?orderBy=DESC&sortBy=total_likes&filter=s&isWeb=1&area=`+this.area+'&offset='+page)
         .then(response => {
            if (!response.error) {
               this.places = response.data.data;
               this.coordinates = response.data.coordinates
            }
         });
      },
   },
};
</script>
